@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';

.menu-item {
    white-space: normal;

    @media @screen-gt-xs {
        white-space: nowrap;
    }
}

.menu-item-active {
    background-color: @color-gray-10;
    color: inherit;

    @media @screen-gt-xs {
        background-color: transparent;
        color: @color-blue-50;
        cursor: default;
    }
}
