@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.press-releases-content {
    display: flex;
    flex-wrap: wrap;
}

.press-release-item {
    margin-bottom: 90px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media @screen-gt-m {
        width: 50%;

        &:nth-child(odd) {
            padding-right: 15px;
        }

        &:nth-child(even) {
            padding-left: 15px;
        }
    }
}

.press-release-title {
    margin-right: 70px;
}

.press-release-announce {
    margin-right: 120px;
}

.press-release-footer {
    border-top: 1px solid @color-gray-20;
}

.press-release-dot {
    margin: 0 8px;
}

.press-release-list-empty {
    color: @color-gray-80;
}
