@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/spacing-base';

.event {
    @media @screen-gt-s {
        display: flex;
    }
}

.event-date {
    margin-bottom: @spacing-base;

    @media @screen-gt-s {
        width: 100px;
        flex-shrink: 0;
        margin-right: 4 * @spacing-base;
    }
}

.event-content {
    color: @color-gray-80;
}
