@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.artlicle-list-empty {
    color: @color-gray-80;
}

.list-item {
    border-bottom: 1px solid;
    border-image: linear-gradient(
        to right,
        fadeout(@color-special-absolute-black, 100%) 20%,
        fadeout(@color-special-absolute-black, 90%),
        fadeout(@color-special-absolute-black, 100%) 80%
    );
    border-image-slice: 1;

    &:last-child {
        border: 0;
    }

    @media @screen-lt-s {
        border: 0;
    }
}
