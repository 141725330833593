@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: calc(-1 * var(--row-gap));

    .card {
        display: flex;
        justify-content: center;
        width: calc((100% - (var(--items-in-row) - 1) * var(--column-gap)) / var(--items-in-row));
        margin-top: var(--row-gap);
    }

    .card.placeholder {
        margin-top: 0;
    }

    &.large {
        @media @screen-gt-s {
            --items-in-row: 4;
        }

        @media @screen-gt-m {
            --items-in-row: 5;
        }
    }

    @media @screen-gt-xs {
        --items-in-row: 3;
        --row-gap: calc(@spacing-base * 6);
    }

    @media @screen-gt-m {
        --items-in-row: 4;
        --column-gap: 30px;
    }
    --column-gap: 20px;
    --row-gap: calc(@spacing-base * 5);
    --items-in-row: 1;
}
