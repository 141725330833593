@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.container {
    border-radius: @rounded-corner-default;
    overflow: hidden;
    position: relative;
    min-height: 345px;

    @media @screen-gt-s {
        min-height: 396px;
    }
}

.slide {
    height: 300px;
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 0;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    padding: 16px 22px 56px;

    &::before {
        content: '';
        background: fade(@color-special-absolute-black, 40%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transition: all 300ms;
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        z-index: 1;
    }

    &:hover {
        &::before {
            background: fade(@color-special-absolute-black, 50%);
        }
    }

    @media @screen-gt-s {
        padding: 48px 20px;
    }
}

.slide-content {
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;
}

.title {
    max-width: 700px;
}

.slider-control {
    border: 1px solid @color-white;
    border-radius: 100%;
    cursor: pointer;
    margin-left: 8px;

    &:first-child {
        margin-left: 0;
    }
}

.slider-control-group {
    display: flex;
    position: absolute;
    right: 16px;
    bottom: 22px;

    @media @screen-gt-xs {
        right: 20px;
        bottom: 20px;
    }
}
