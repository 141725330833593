@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/drop';
@import '~bloko/common/styles/spacing-base';

@menu-gap: 25px;
@drop-offset: calc(3 * @spacing-base);

.desktop-menu {
    overflow: hidden;
    display: none;

    @media @screen-gt-xs {
        display: block;
    }
}

.mobile-menu {
    margin: 0 -15px;
    display: block;

    @media @screen-gt-xs {
        display: none;
    }
}

.container {
    position: relative;
    border-bottom: 1px solid @color-gray-20;

    @media @screen-gt-xs {
        border-bottom: 0;
    }
}

.menu {
    display: block;
    background: @color-white;
    position: absolute;
    left: 0;
    top: calc(~'100% + 1px');
    width: 100%;
    z-index: @overlay;
    overflow: scroll;

    @media @screen-gt-xs {
        display: flex;
        align-items: center;
        background: transparent;
        position: static;
        min-height: auto;
        overflow: hidden;
        justify-content: space-between;
    }
}

.menu-wrapper {
    height: calc(100% - 120px);
    overflow: scroll;
}

.menu-item {
    padding: 9px 15px;
    white-space: normal;

    @media @screen-gt-xs {
        padding: 0 0 @drop-offset @menu-gap;
        white-space: nowrap;

        &:first-of-type {
            padding-left: 0;
        }
    }
}

.menu-item-active {
    background-color: @color-gray-10;
    color: inherit;

    @media @screen-gt-xs {
        background-color: transparent;
        color: @color-blue-50;
        cursor: default;
    }
}

.interactive-buttons {
    display: flex;
}

.interactive-button {
    cursor: pointer;
    margin-left: @menu-gap;
    padding-bottom: @drop-offset;
}

.fake-button {
    height: 0;
    position: absolute;
    padding-left: @menu-gap;
    overflow: hidden;
}

.fake-menu {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 0;
    position: absolute;
}

.menu-item-content {
    display: flex;
    justify-content: space-between;
}

.menu-item-wrapper {
    margin-left: -@bloko-drop-padding-left;
    margin-right: -@bloko-drop-padding-right;
}

.nav {
    cursor: pointer;
    display: flex;
    align-items: stretch;
}

.nav-item-menu {
    flex-grow: 1;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.nav-item-search {
    padding: 10px 15px;
}

.nav-item-active {
    color: @color-blue-50;
}

.submenu {
    border-bottom: 1px solid @color-gray-20;
}

.search-container {
    display: flex;
    padding: 15px;

    @media @screen-gt-xs {
        padding: 0;
        width: auto;
        min-width: 385px;
    }
}
