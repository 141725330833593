@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';

.tag-variant(@tag-color, @tag-color-hover) {
    background: @tag-color;

    &:active,
    &:focus,
    &:hover {
        background: @tag-color-hover;
        color: @color-gray-80;
    }
}

.tag {
    padding: 2px 8px;
    border-radius: @rounded-corner-default;
}

.tag-large {
    padding: 8px 16px;
}

.tag-red {
    .tag-variant(@color-red-10, @color-red-20);
}

.tag-blue {
    .tag-variant(@color-blue-10, @color-blue-20);
}

.tag-green {
    .tag-variant(@color-green-10, @color-green-20);
}

.tag-yellow {
    .tag-variant(@color-yellow-10, @color-yellow-20);
}

.tag-grey {
    .tag-variant(@color-gray-10, @color-gray-20);
}

.tag-violet {
    .tag-variant(@color-violet-10, @color-violet-20);
}

.tag-orange {
    .tag-variant(@color-orange-10, @color-orange-20);
}
