@import '~bloko/common/styles/rounded-corner-base';

.compact {
    width: 100%;
    max-width: 290px;

    &:hover .compact-preview {
        transform: scale(1.1);
    }
}

.compact-preview-container {
    width: 100%;
    border-radius: @rounded-corner-default;
    padding-top: 62%;
    position: relative;
    overflow: hidden;
}

.compact-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: all 0.2s linear;
}
