@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/colors';

.events-filters {
    border-bottom: 1px solid @color-gray-20;
}

.events-select {
    width: 100%;
    margin-bottom: 3 * @spacing-base;

    @media @screen-gt-xs {
        width: 195px;
        display: inline-block;
        margin-right: 2 * @spacing-base;
    }
}
