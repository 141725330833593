@import '~bloko/common/styles/media/_screen';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list {
    margin: 0 -15px;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    @media @screen-gt-xs {
        row-gap: 32px;
    }
}

.item-container {
    @media @screen-lt-s {
        width: 100%;
    }
}

@media @screen-lt-s {
    .hide-xs {
        display: none;
    }
}

@media @screen-s {
    .hide-s {
        display: none;
    }
}

@media @screen-m {
    .hide-m {
        display: none;
    }
}

@media @screen-gt-m {
    .hide-l {
        display: none;
    }
}
